import React, { useState } from "react";
import { firestore, storage } from "../../firebaseProvider";

import "./Article.css";
import SaveInfoText from "../SaveInfoText/SaveInfoText";
import { FaTrashAlt } from "react-icons/fa";
import { SecundaryButton, IconButton } from "../Button/Button";

const ArticleCreate = ({ collection, onSave, order }) => {
  const [newPhotoCarusel, setNewPhotoCarusel] = useState([]);

  const [newTitleDE, setNewTitleDE] = useState("");
  const [newSubtitleDE, setNewSubtitleDE] = useState("");
  const [newContentDE, setNewContentDE] = useState("");
  const [newButtonTextDE, setNewButtonTextDE] = useState("");
  const [newLinkUrlDE, setNewLinkUrlDE] = useState("");

  const [newTitleEN, setNewTitleEN] = useState("");
  const [newSubtitleEN, setNewSubtitleEN] = useState("");
  const [newContentEN, setNewContentEN] = useState("");
  const [newButtonTextEN, setNewButtonTextEN] = useState("");
  const [newLinkUrlEN, setNewLinkUrlEN] = useState("");

  const [showInfo, setShowInfo] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState();
  const [noDataEntered, setNoDataEntered] = useState();

  const resetValues = () => {
    setNewPhotoCarusel([]);
    setNewTitleDE("");
    setNewSubtitleDE("");
    setNewContentDE("");
    setNewButtonTextDE("");
    setNewLinkUrlDE("");
    setNewTitleEN("");
    setNewSubtitleEN("");
    setNewContentEN("");
    setNewButtonTextEN("");
    setNewLinkUrlEN("");
  };

  const saveChanges = () => {
    if (checkInputIsValid()) {
      const dataToStore = {
        titleDE: newTitleDE,
        contentDE: newContentDE,
        titleEN: newTitleEN,
        contentEN: newContentEN,
        order: order,
      };

      if (newSubtitleDE) {
        dataToStore["subtitleDE"] = newSubtitleDE;
      }
      if (newSubtitleEN) {
        dataToStore["subtitleEN"] = newSubtitleEN;
      }
      if (newPhotoCarusel !== []) {
        let names = [];
        newPhotoCarusel.forEach((item) => {
          names.push(item.name);
        });
        dataToStore["photoNames"] = names;
      }
      if (newButtonTextDE) {
        dataToStore["buttonTextDE"] = newButtonTextDE;
        dataToStore["buttonTextEN"] = newButtonTextEN;
        dataToStore["buttonType"] = "link";
        dataToStore["linkUrlDE"] = newLinkUrlDE;
        dataToStore["linkUrlEN"] = newLinkUrlEN;
      }

      firestore
        .collection(collection)
        .doc()
        .set(dataToStore)
        .then(() => {
          if (newPhotoCarusel !== []) {
            newPhotoCarusel.forEach((item) => {
              storage.child(collection).child(item.name).put(item);
            });
          }
          setSaveSuccess(true);
          setShowInfo(true);
          resetValues();
          onSave();
        })
        .catch((err) => {
          setSaveSuccess(false);
          setShowInfo(true);
        });
    } else {
      setShowInfo(true);
      setNoDataEntered(true);
    }
  };

  const checkInputIsValid = () => {
    if (
      !newTitleDE &&
      !newContentDE &&
      !newTitleEN &&
      !newContentEN &&
      !newPhotoCarusel
    ) {
      return false;
    } else if (
      newButtonTextDE ||
      newButtonTextEN ||
      newLinkUrlDE ||
      newLinkUrlEN
    ) {
      if (
        !newButtonTextDE ||
        !newButtonTextEN ||
        !newLinkUrlDE ||
        !newLinkUrlEN
      ) {
        return false;
      }
    }
    return true;
  };

  const onPhotoUpload = (event) => {
    const photos = event.target.files;
    for (const item of photos) {
      setNewPhotoCarusel((oldState) => [...oldState, item]);
    }
  };

  const deletePhoto = (photoName) => {
    let editedPhotoCarusel = [];

    for (const item of newPhotoCarusel) {
      if (item.name !== photoName) {
        editedPhotoCarusel.push(item);
      }
    }
    setNewPhotoCarusel(editedPhotoCarusel);
  };

  const resetInfoValues = () => {
    setShowInfo(false);
    setNoDataEntered();
  };

  return (
    <div className="article">
      <div className="article-create">
        <h1>Fotos hochladen</h1>
        <div className="article-file-upload">
          <input type="file" name="file" onChange={onPhotoUpload} multiple />
          {newPhotoCarusel.map((item) => (
            <div key={item.name} className="uploaded">
              <p>{item.name}</p>
              <IconButton
                onClick={() => deletePhoto(item.name)}
                icon={<FaTrashAlt />}
              />
            </div>
          ))}
        </div>
        <hr />
        <h1>Deutsche Version</h1>
        <div>
          <label>Titel</label>
          <input
            type="text"
            value={newTitleDE}
            onInput={(e) => setNewTitleDE(e.target.value)}
            onChange={() => showInfo && resetInfoValues()}
          />
        </div>
        <div>
          <label>Untertitel</label>
          <input
            type="text"
            value={newSubtitleDE}
            onInput={(e) => setNewSubtitleDE(e.target.value)}
            onChange={() => showInfo && resetInfoValues()}
          />
        </div>
        <div>
          <label>Artikel</label>
          <textarea
            value={newContentDE}
            onInput={(e) => setNewContentDE(e.target.value)}
            onChange={() => showInfo && resetInfoValues()}
          />
        </div>
        <div>
          <label>Buttontext</label>
          <input
            type="text"
            value={newButtonTextDE}
            onInput={(e) => setNewButtonTextDE(e.target.value)}
            onChange={() => showInfo && resetInfoValues()}
          />
        </div>

        <div>
          <label>Buttonlink (Bsp. ./Videos)</label>
          <input
            type="text"
            value={newLinkUrlDE}
            onInput={(e) => setNewLinkUrlDE(e.target.value)}
            onChange={() => showInfo && resetInfoValues()}
          />
        </div>
      </div>
      <hr />
      <h1>Englische Version</h1>
      <div>
        <label>Titel</label>
        <input
          type="text"
          value={newTitleEN}
          onInput={(e) => setNewTitleEN(e.target.value)}
          onChange={() => showInfo && resetInfoValues()}
        />
      </div>
      <div>
        <label>Untertitel</label>
        <input
          type="text"
          value={newSubtitleEN}
          onInput={(e) => setNewSubtitleEN(e.target.value)}
          onChange={() => showInfo && resetInfoValues()}
        />
      </div>
      <div>
        <label>Artikel</label>
        <textarea
          value={newContentEN}
          onInput={(e) => setNewContentEN(e.target.value)}
          onChange={() => showInfo && resetInfoValues()}
        />
      </div>
      <div>
        <label>Buttontext</label>
        <input
          type="text"
          value={newButtonTextEN}
          onInput={(e) => setNewButtonTextEN(e.target.value)}
          onChange={() => showInfo && resetInfoValues()}
        />
      </div>
      <div>
        <label>Buttonlink (Bsp. ./Videos)</label>
        <input
          type="text"
          value={newLinkUrlEN}
          onInput={(e) => setNewLinkUrlEN(e.target.value)}
          onChange={() => showInfo && resetInfoValues()}
        />
      </div>
      <div className="save-button">
        <SecundaryButton
          onClick={saveChanges}
          text={"Speichern"}
          disabled={false}
        />
      </div>
      {showInfo && noDataEntered && (
        <div className="form-info">
          <p>
            Titel oder Text oder Foto dürfen nicht leer sein. Für einen Button
            müssen alle Button Felder ausgefüllt werden.
          </p>
        </div>
      )}
      {showInfo && <SaveInfoText saveSuccess={saveSuccess} />}
    </div>
  );
};

export default ArticleCreate;
