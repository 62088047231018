import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { getDocument } from "../../firebaseProvider";

import { LinkButton } from "../../components/Button/Button";
import "./Videos.css";

import MainVideoPlayer from "../../components/VideoPlayer/MainVideoPlayer";

const HomeVideoPlayer = ({ user }) => {
  const { t } = useTranslation();
  const [data, setData] = useState();

  if (!data) {
    getDocument("videos", "MainVideo").then((input) => {
      setData(input);
    });
  }

  return data ? (
    <div id="home-video-section">
      <div id="more-videos-button">
        <LinkButton text={t("home.moreVideos")} link="./videos" />
      </div>
      <MainVideoPlayer user={user} data={data} id={"MainVideo"} />
    </div>
  ) : (
    <div></div>
  );
};

export default HomeVideoPlayer;
