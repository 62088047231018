import React, { useState } from "react";

import "./VideoPlayer.css";
import { SecundaryButton } from "../Button/Button";
import SaveInfoText from "../SaveInfoText/SaveInfoText";

const VideoPlayerEdit = ({ data, setData, saveSuccess }) => {
  const [newTitleDE, setNewTitleDE] = useState(data.titleDE);
  const [newTitleEN, setNewTitleEN] = useState(data.titleEN);
  const [newURLDE, setNewURLDE] = useState(data.urlDE);
  const [newURLEN, setNewURLEN] = useState(data.urlEN);

  const [noDataEntered, setNoDataEntered] = useState();
  const [showInfo, setShowInfo] = useState(false);

  const saveChanges = () => {
    if (newTitleDE && newTitleEN && newURLDE && newURLEN) {
      const dataToStore = {
        titleDE: newTitleDE,
        titleEN: newTitleEN,
        urlDE: newURLDE,
        urlEN: newURLEN,
        order: data.order,
      };

      setData(dataToStore);
      setShowInfo(true);
    } else {
      setShowInfo(true);
      setNoDataEntered(true);
    }
  };

  const resetInfoValues = () => {
    setShowInfo(false);
    setNoDataEntered();
  };

  return (
    <div className="video">
      <div className="video-edit-container">
        <div className="video-edit">
          <h2>Deutsche Version</h2>
          <div>
            <label>Titel</label>
            <input
              type="text"
              value={newTitleDE}
              onInput={(e) => setNewTitleDE(e.target.value)}
              onChange={() => showInfo && resetInfoValues()}
            />
          </div>
          <div>
            <label>URL</label>
            <input
              type="text"
              value={newURLDE}
              onInput={(e) => setNewURLDE(e.target.value)}
              onChange={() => showInfo && resetInfoValues()}
            />
          </div>
        </div>
        <hr />
        <div className="video-edit">
          <h2>Englische Version</h2>
          <div>
            <label>Titel</label>
            <input
              type="text"
              value={newTitleEN}
              onInput={(e) => setNewTitleEN(e.target.value)}
              onChange={() => showInfo && resetInfoValues()}
            />
          </div>
          <div>
            <label>URL</label>
            <input
              type="text"
              value={newURLEN}
              onInput={(e) => setNewURLEN(e.target.value)}
              onChange={() => showInfo && resetInfoValues()}
            />
          </div>
          <div className="save-button">
            <SecundaryButton
              onClick={saveChanges}
              text={"Speichern"}
              disabled={false}
            />
          </div>
          {showInfo && noDataEntered && (
            <div className="form-info">
              <p>Titel und Text dürfen nicht leer sein.</p>
            </div>
          )}
          {showInfo && <SaveInfoText saveSuccess={saveSuccess} />}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerEdit;
