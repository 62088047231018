import React from "react";

import "./SaveInfoText.css";

const SaveInfoText = ({ success, text }) => {
  return (
    <div>
      {success && (
        <div className="save-success">
          <p>{text || "erfolgreich gespeichert"}</p>
        </div>
      )}
      {success === false && (
        <div className="save-error">
          <p>
            {text || "Speichern hat nicht funktioniert! Versuche es nochmal."}
          </p>
        </div>
      )}
    </div>
  );
};

export default SaveInfoText;
