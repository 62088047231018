import React from "react";
import { useTranslation } from "react-i18next";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth } from "../../firebaseProvider";
import "./Immoball.css";

import InfoRow from "../../components/InfoRow/InfoRow";
import { Footer } from "../../components/Footer/Footer";
import GallaryBall from "../../components/GalleryBall/GallaryBall";
import BallTitle from "./BallTitle";
import EventDonators from "../../components/Donators/EventDonators";
import Whatsup from "./Whatsup";
import ImmoballVideoPlayer from "../Videos/ImmoballVideoPlayer";

const Immoball = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div id="immoball">
      <div>{t()}</div>
      <BallTitle user={user} />
      <div className="immoball-content-intro">
        <InfoRow user={user} collection="immoball-intro" numOfItems={1} />
      </div>
      <hr />
      <div className="home-content">
        <ImmoballVideoPlayer user={user} />
      </div>
      <div className="immoball-content">
        <InfoRow user={user} collection="immoball-info" numOfItems={2} />
      </div>
      <hr />
      <div className="whatsUp">
        <Whatsup user={user} />
      </div>

      <hr />
      <div className="immoball-content">
        <GallaryBall collection="immoball" />
      </div>
      <div className="immoball-content">
        <EventDonators category="ball" />
      </div>
      <Footer />
    </div>
  );
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(Immoball);
