import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth, getSortedData } from "../../firebaseProvider";
import "./Immoball.css";

import ArticleGrid from "../../components/Article/ArticleGrid";
import ArticleCreate from "../../components/Article/ArticleCreate";
import { Title } from "../../components/Title/Title";

const Whatsup = ({ user }) => {
  const [data, setData] = useState();
  const { t } = useTranslation();
  const loadData = () => {
    getSortedData("immoball-whatsup")
      .then((input) => {
        setData(input);
      })
      .catch((err) => {
        console.log("error getting data: ", err);
      });
  };

  if (!data) {
    loadData();
  }

  const getNewsView = () => {
    if (data) {
      return (
        <div id="whatsup">
          {user && (
            <div>
              <Title text={t("ball.whatsupTitle")} />
              <div className="whatsup-create">
                <div className="whatsup-create-article">
                  <ArticleCreate
                    collection="immoball-whatsup"
                    onSave={loadData}
                    order={data.length + 1}
                  />
                </div>
              </div>
            </div>
          )}
          <div>
            {!user && <Title text={t("ball.whatsupTitle")} />}
            <ArticleGrid
              user={user}
              data={data}
              collection="immoball-whatsup"
              editable={true}
              numPerRow={2}
              reloadData={loadData}
              expandable={true}
            />
          </div>
        </div>
      );
    } else {
      return <div>no data</div>;
    }
  };

  return getNewsView();
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(Whatsup);
