import React from "react";
import { useTranslation } from "react-i18next";

import "./Legal.css";

import { Title } from "../../components/Title/Title";

const Legal = () => {
  const { t } = useTranslation();

  return (
    <div id="legal">
      <div>
        <Title text={t("legal.title")} />
        <div className="legal-content">
          <h4>{t("legal.title1")}</h4>
          <p>{t("legal.text1")}</p>
        </div>
        <div className="legal-content">
          <h4>{t("legal.title2")}</h4>
          <p>{t("legal.text2")}</p>
        </div>
        <div className="legal-content">
          <h4>{t("legal.title3")}</h4>
          <p>{t("legal.text3")}</p>
        </div>
        <div className="legal-content">
          <h4>{t("legal.title4")}</h4>
          <p>{t("legal.text4")}</p>
          <p>{t("legal.text5")}</p>
          <ul>
            <li>{t("legal.ul1")}</li>
            <li>{t("legal.ul2")}</li>
            <li>{t("legal.ul3")}</li>
            <li>{t("legal.ul4")}</li>
            <li>{t("legal.ul5")}</li>
            <li>{t("legal.ul6")}</li>
          </ul>
          <p>{t("legal.text6")}</p>
          <p>{t("legal.text7")}</p>
          <p>{t("legal.text8")}</p>
          <p>{t("legal.text9")}</p>
        </div>
      </div>
    </div>
  );
};

export default Legal;
