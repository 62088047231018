import React, { useState } from "react";

import "./Article.css";
import SaveInfoText from "../SaveInfoText/SaveInfoText";
import { FaTrashAlt } from "react-icons/fa";
import { SecundaryButton, IconButton } from "../Button/Button";

const ArticleEdit = ({ data, setData, saveSuccess }) => {
  const [newPhotoCaruselNames, setNewPhotoCaruselNames] = useState(
    data.photoNames
  );
  const [newPhotoCarusel, setNewPhotoCarusel] = useState([]);

  const [newTitleDE, setNewTitleDE] = useState(data.titleDE);
  const [newSubtitleDE, setNewSubtitleDE] = useState(data.subtitleDE);
  const [newContentDE, setNewContentDE] = useState(data.contentDE);
  const [newButtonTextDE, setNewButtonTextDE] = useState(data.buttonTextDE);
  const [newLinkUrlDE, setNewLinkUrlDE] = useState(data.linkUrlDE);

  const [newTitleEN, setNewTitleEN] = useState(data.titleEN);
  const [newSubtitleEN, setNewSubtitleEN] = useState(data.subtitleEN);
  const [newContentEN, setNewContentEN] = useState(data.contentEN);
  const [newButtonTextEN, setNewButtonTextEN] = useState(data.buttonTextEN);
  const [newLinkUrlEN, setNewLinkUrlEN] = useState(data.linkUrlEN);

  const [noDataEntered, setNoDataEntered] = useState();
  const [showInfo, setShowInfo] = useState(false);

  const saveChanges = () => {
    if (checkInputIsValid()) {
      const dataToStore = {
        titleDE: newTitleDE,
        contentDE: newContentDE,
        titleEN: newTitleEN,
        contentEN: newContentEN,
        order: data.order,
      };

      if (newSubtitleDE) {
        dataToStore["subtitleDE"] = newSubtitleDE;
      }
      if (newSubtitleEN) {
        dataToStore["subtitleEN"] = newSubtitleEN;
      }

      if (newButtonTextDE) {
        dataToStore["buttonTextDE"] = newButtonTextDE;
        dataToStore["buttonTextEN"] = newButtonTextEN;
        dataToStore["buttonType"] = "link";
        dataToStore["linkUrlDE"] = newLinkUrlDE;
        dataToStore["linkUrlEN"] = newLinkUrlEN;
      }

      if (newPhotoCarusel !== []) {
        dataToStore["photoNames"] = newPhotoCaruselNames;
      }
      setData(dataToStore, data.photoNames, newPhotoCarusel);
      setShowInfo(true);
    } else {
      setShowInfo(true);
      setNoDataEntered(true);
    }
  };

  const checkInputIsValid = () => {
    if (
      !newTitleDE &&
      !newContentDE &&
      !newTitleEN &&
      !newContentEN &&
      !newPhotoCarusel
    ) {
      return false;
    } else if (
      newButtonTextDE ||
      newButtonTextEN ||
      newLinkUrlDE ||
      newLinkUrlEN
    ) {
      if (
        !newButtonTextDE ||
        !newButtonTextEN ||
        !newLinkUrlDE ||
        !newLinkUrlEN
      ) {
        return false;
      }
    }
    return true;
  };

  const resetValues = () => {
    setShowInfo(false);
    setNoDataEntered();
  };

  const onPhotoUpload = (event) => {
    const photos = event.target.files;
    for (const item of photos) {
      setNewPhotoCarusel((oldState) => [...oldState, item]);
      setNewPhotoCaruselNames((oldState) => [...oldState, item.name]);
    }
  };

  const deletePhoto = (photoName) => {
    let editedPhotoCarusel = [];
    let editedPhotoCaruselNames = [];

    for (const item of newPhotoCarusel) {
      if (item.name !== photoName) {
        editedPhotoCarusel.push(item);
      }
    }
    setNewPhotoCarusel(editedPhotoCarusel);

    for (const item of newPhotoCaruselNames) {
      if (item !== photoName) {
        editedPhotoCaruselNames.push(item);
      }
    }
    setNewPhotoCaruselNames(editedPhotoCaruselNames);
  };

  return (
    <div className="article">
      <div className="article-edit">
        <h2>neues Foto hochladen</h2>
        <div className="article-file-upload">
          <input type="file" name="file" onChange={onPhotoUpload} multiple />
          {newPhotoCaruselNames &&
            newPhotoCaruselNames.map((item) => (
              <div key={item} className="uploaded">
                <p>{item}</p>
                <IconButton
                  onClick={() => deletePhoto(item)}
                  icon={<FaTrashAlt />}
                />
              </div>
            ))}
        </div>
        <h2>Deutsche Version</h2>
        <div>
          <label>Titel</label>
          <input
            type="text"
            value={newTitleDE}
            onInput={(e) => setNewTitleDE(e.target.value)}
            onChange={() => showInfo && resetValues()}
          />
        </div>
        <div>
          <label>Untertitel</label>
          <input
            type="text"
            value={newSubtitleDE}
            onInput={(e) => setNewSubtitleDE(e.target.value)}
            onChange={() => showInfo && resetValues()}
          />
        </div>
        <div>
          <label>Artikel</label>
          <textarea
            value={newContentDE}
            onInput={(e) => setNewContentDE(e.target.value)}
            onChange={() => showInfo && resetValues()}
          />
        </div>
        <div>
          <label>Buttontext</label>
          <input
            type="text"
            value={newButtonTextDE}
            onInput={(e) => setNewButtonTextDE(e.target.value)}
            onChange={() => showInfo && resetValues()}
          />
        </div>

        <div>
          <label>Buttonlink (Bsp. ./Videos)</label>
          <input
            type="text"
            value={newLinkUrlDE}
            onInput={(e) => setNewLinkUrlDE(e.target.value)}
            onChange={() => showInfo && resetValues()}
          />
        </div>
      </div>
      <hr />
      <h2>Englische Version</h2>
      <div>
        <label>Titel</label>
        <input
          type="text"
          value={newTitleEN}
          onInput={(e) => setNewTitleEN(e.target.value)}
          onChange={() => showInfo && resetValues()}
        />
      </div>
      <div>
        <label>Untertitel</label>
        <input
          type="text"
          value={newSubtitleEN}
          onInput={(e) => setNewSubtitleEN(e.target.value)}
          onChange={() => showInfo && resetValues()}
        />
      </div>
      <div>
        <label>Artikel</label>
        <textarea
          value={newContentEN}
          onInput={(e) => setNewContentEN(e.target.value)}
          onChange={() => showInfo && resetValues()}
        />
      </div>
      <div>
        <label>Buttontext</label>
        <input
          type="text"
          value={newButtonTextEN}
          onInput={(e) => setNewButtonTextEN(e.target.value)}
          onChange={() => showInfo && resetValues()}
        />
      </div>
      <div>
        <label>Buttonlink (Bsp. ./Videos)</label>
        <input
          type="text"
          value={newLinkUrlEN}
          onInput={(e) => setNewLinkUrlEN(e.target.value)}
          onChange={() => showInfo && resetValues()}
        />
      </div>
      <div className="save-button">
        <SecundaryButton
          onClick={saveChanges}
          text={"Speichern"}
          disabled={false}
        />
      </div>
      {showInfo && noDataEntered && (
        <div className="form-info">
          <p>
            Titel und Text dürfen nicht leer sein. Für einen Button müssen alle
            Button Felder ausgefüllt werden.
          </p>
        </div>
      )}
      {showInfo && <SaveInfoText saveSuccess={saveSuccess} />}
    </div>
  );
};

export default ArticleEdit;
