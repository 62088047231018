import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./PhotoCarousel.css";

const PhotoCarousel = ({ photoUrls }) => {
  const getPhotos = (data) => {
    return data.map((item) => (
      <div key={item}>
        <img src={item} alt="" />
      </div>
    ));
  };

  return (
    <Carousel showThumbs={false} showStatus={false}>
      {photoUrls && getPhotos(photoUrls)}
    </Carousel>
  );
};

export default PhotoCarousel;
