import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth, getSortedData } from "../../firebaseProvider";
import "./Projects.css";

import { Footer } from "../../components/Footer/Footer";
import { Title } from "../../components/Title/Title";
import InfoRow from "../../components/InfoRow/InfoRow";

const KPLC = ({ user }) => {
  const { t } = useTranslation();

  const [studentData, setStudentData] = useState();
  const [teacherData, setTeacherData] = useState();

  const loadStudentData = () => {
    getSortedData("kplc-students").then((input) => setStudentData(input));
  };
  const loadTeacherData = () => {
    getSortedData("kplc-teacher").then((input) => setTeacherData(input));
  };

  if (!studentData) {
    loadStudentData();
  }
  if (!teacherData) {
    loadTeacherData();
  }

  return teacherData && studentData ? (
    <div id="kplc">
      <Title text={t("projects.kplc")} />
      <div className="kplc-content-intro">
        <InfoRow user={user} collection="kplc-info" numOfItems={1} />
      </div>
      {/*
      <hr />
      <div className="kplc-content">
        <Title text={t("projects.students")} />
        <ArticleGrid
          user={user}
          data={studentData}
          collection="kplc-students"
          editable={true}
          numPerRow={6}
          reloadData={loadStudentData}
          expandable={false}
        />
        <div className="kplc-create">
          {user && <ArticleCreate
            collection="kplc-students"
            onSave={loadStudentData}
            order={studentData.length + 1}
          />}
        </div>
      </div>
      <hr />
      <div className="kplc-content">
        <Title text={t("projects.teacher")} />
        <ArticleGrid
          user={user}
          data={teacherData}
          collection="kplc-teacher"
          editable={true}
          numPerRow={6}
          reloadData={loadTeacherData}
          expandable={false}
        />
        <div className="kplc-create">
          {user && <ArticleCreate
            collection="kplc-teacher"
            onSave={loadTeacherData}
            order={teacherData.length + 1}
          />}
        </div>
      </div>
       */}
      <Footer />
    </div>
  ) : (
    <div></div>
  );
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(KPLC);
