import React from "react";
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth } from "../../firebaseProvider";
import "./Home.css";

import { DonationButton } from "../../components/Button/Button";
import logo from "../../images/logo_weiss.png";
import InfoRow from "../../components/InfoRow/InfoRow";
import HomeVideoPlayer from "../Videos/HomeVideoPlayer";
import NewsOverview from "../News/NewsOverview";
import Botschafter from "./Botschafter";
import Donators from "../../components/Donators/Donators";
import { Footer } from "../../components/Footer/Footer";

const Home = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div id="home">
      <CookieConsent
        location="bottom"
        buttonText="ok"
        cookieName="Disclamer Coockie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {t("general.cookie")}
      </CookieConsent>
      <div>
        <DonationButton />
      </div>
      <div id="home-title">
        <img src={logo} alt="Logo" />
        <h1>{t("home.title")}</h1>
      </div>
      <div className="home-content">
        <InfoRow user={user} collection="who" numOfItems={3} />
      </div>
      <hr />
      <div className="home-content">
        <HomeVideoPlayer user={user} />
      </div>
      <hr />
      <div className="home-content">
        <NewsOverview user={user} />
      </div>
      <hr />
      <div className="home-content">
        <Botschafter user={user} />
      </div>
      <hr />
      <div className="home-content">
        <Donators user={user} />
      </div>
      <Footer />
    </div>
  );
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(Home);
