import React, { useState } from "react";
import ReactPlayer from "react-player";
import i18next from "i18next";

import { storeData } from "../../firebaseProvider";

import { SecundaryButton } from "../Button/Button";
import "./VideoPlayer.css";
import SaveInfoText from "../SaveInfoText/SaveInfoText";
import { Title } from "../Title/Title";

const MainVideoPlayer = ({ user, data, id }) => {
  const [urlDE, setUrlDE] = useState(data.urlDE);
  const [urlEN, setUrlEN] = useState(data.urlEN);
  const [titleDE, setTitleDE] = useState(data.titleDE);
  const [titleEN, setTitleEN] = useState(data.titleEN);
  const [saveSuccess, setSaveSuccess] = useState();
  const [saveText, setSaveText] = useState();

  const saveNewURL = () => {
    if (urlDE && urlEN) {
      storeData("videos", id, {
        urlDE: urlDE,
        urlEN: urlEN,
        titleDE: titleDE,
        titleEN: titleEN,
        order: data.order,
      })
        .then(() => {
          setSaveSuccess(true);
          setSaveText("erfolgreich gespeichert");
        })
        .catch(() => {
          setSaveSuccess(false);
          setSaveText("speichern hat nicht funktioniert, probiere es nochmal");
        });
    } else {
      setSaveSuccess(false);
      setSaveText("URL darf nicht leer sein.");
    }
  };

  return urlDE && urlEN ? (
    <div className="main-video-player">
      <div className="video-player">
        {titleDE && titleEN && <Title text={titleDE} />}
        <ReactPlayer
          url={i18next.language.substring(0, 2) === "de" ? urlDE : urlEN}
          width={window.innerWidth * (0.9).toString() + "px"}
          height={(window.innerWidth / 1.97).toString() + "px"}
          playing={true}
          volume={0.2}
          muted={true}
          controls={true}
        />
      </div>
      {user && (
        <div>
          <label>Titel Deutsch</label>
          <input
            value={titleDE}
            onInput={(e) => {
              setTitleDE(e.target.value);
              setSaveText("Achtung: Speichern nicht vergessen");
              setSaveSuccess(false);
            }}
            onChange={() => {}}
          />
          <label>Titel Englisch</label>
          <input
            value={titleEN}
            onInput={(e) => {
              setTitleEN(e.target.value);
              setSaveText("Achtung: Speichern nicht vergessen");
              setSaveSuccess(false);
            }}
            onChange={() => {}}
          />
          <label>Video URL Deutsch</label>
          <input
            value={urlDE}
            onInput={(e) => {
              setUrlDE(e.target.value);
              setSaveText("Achtung: Speichern nicht vergessen");
              setSaveSuccess(false);
            }}
            onChange={() => {}}
          />
          <label>Video URL Englisch</label>
          <input
            value={urlEN}
            onInput={(e) => {
              setUrlEN(e.target.value);
              setSaveText("Achtung: Speichern nicht vergessen");
              setSaveSuccess(false);
            }}
            onChange={() => {}}
          />
          <SecundaryButton
            text="speichern"
            onClick={saveNewURL}
            disabled={false}
          />
          <SaveInfoText success={saveSuccess} text={saveText} />
        </div>
      )}
    </div>
  ) : (
    <div></div>
  );
};

export default MainVideoPlayer;
