import React from "react";
import "./FormattedTable.css";

export const FormattedTable = (data) => {
  return (
    <div className="formatted-table">
      {data && data.data && (
        <table>
          <tbody>
            {Object.values(data.data).map((item) => (
              <tr key={item[1]}>
                <td>{item[0]}</td>
                <td>{item[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
