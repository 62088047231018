import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { storage } from "../../firebaseProvider";

import { Title } from "../Title/Title";

import "./Donators.css";

const DonatorsView = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div id="donators-view">
      <Title text={t("home.donators")} />
      {data && (
        <div className="donators-grid">
          <div className="grid-item main-partners">
            <h2>{t("home.countryPartners")}</h2>
            {data["country-partner"] &&
              data["country-partner"].map((item) => (
                <DonatorImage key={item} imageName={item} />
              ))}
          </div>
          <div className="grid-item fundraising-partners">
            <h2>{t("home.fundraisingPartners")}</h2>
            {data["fundraising-partner"] &&
              data["fundraising-partner"].map((item) => (
                <DonatorImage key={item} imageName={item} />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const DonatorImage = ({ imageName }) => {
  const [photoUrl, setPhotoUrl] = useState();

  if (imageName && !photoUrl) {
    storage
      .child("donators")
      .child(imageName)
      .getDownloadURL()
      .then((url) => setPhotoUrl(url));
  }
  return (
    <div className="donator-img">
      {photoUrl && <img src={photoUrl} alt=""></img>}
    </div>
  );
};

export default DonatorsView;
