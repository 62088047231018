import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./Footer.css";
import facebookLogo from "../../images/facebook.png";
import twitterLogo from "../../images/twitter.png";
import instagramLogo from "../../images/instagram.png";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div id="footer">
      <div id="footer-content">
        <div className="footer-item">
          <div>
            <Link className="link" to={"/about"}>
              <p>{t("footer.about")}</p>
            </Link>
          </div>
          <div>
            <Link className="link" to={"/contact"}>
              <p>{t("footer.contact")}</p>
            </Link>
          </div>
          <div>
            <Link className="link" to={"/legal"}>
              <p>{t("footer.legal")}</p>
            </Link>
          </div>
        </div>
        <div className="footer-item">
          {t("footer.address")}: <br />
          <br />
          kidz planet
          <br />
          Kastellstrasse 23
          <br />
          8107 Buchs
          <br />
          {t("footer.phone")}: +41 79 610 47 70
        </div>
        <div className="footer-item">
          {t("footer.donationsAccount")}:
          <br />
          <br />
          Raiffeisenbank
          <br />
          Züri-Unterland
          <br />
          8180 Bülach
          <br />
          IBAN: CH75 8080 8006 5101 2921 3<br />
          SWIFT-BIC: RAIFCH22E75
          <br />
          BC-Nr.: 81475
        </div>
        <div id="social-links">
          <a
            className="social-link"
            href={
              "https://www.facebook.com/kidz-planet-994730793881583/?hc_ref=ARS9zvaAQdMAczvgNSl_Fw8EuvYyGuOZ8NDEO4c8CI0EXRoT1lbvk4cMk2sha5qe_1E&fref=nf"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookLogo} alt="Facebook" />
          </a>
          <a
            className="social-link"
            href={"https://twitter.com/kidz_planet"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterLogo} alt="Twitter" />
          </a>
          <a
            className="social-link"
            href={"https://instagram.com/kidzplanet.ch?igshid=1rnthn2mgnx14"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramLogo} alt="Instagram" />
          </a>
        </div>
      </div>
    </div>
  );
};

export const FooterSmall = () => {
  const { t } = useTranslation();

  return (
    <div id="footer">
      <div id="footer-content-small">
        <div>
          <div>
            <Link className="link" to={"/about"}>
              <p>{t("footer.about")}</p>
            </Link>
          </div>
          <div>
            <Link className="link" to={"/contact"}>
              <p>{t("footer.contact")}</p>
            </Link>
          </div>
          <div>
            <Link className="link" to={"/legal"}>
              <p>{t("footer.legal")}</p>
            </Link>
          </div>
        </div>
        <div id="social-links">
          <a className="social-link" href={"/"}>
            <img src={facebookLogo} alt="Facebook" />
          </a>
          <a className="social-link" href={"/"}>
            <img src={twitterLogo} alt="Twitter" />
          </a>
          <a className="social-link" href={"/"}>
            <img src={instagramLogo} alt="Instagram" />
          </a>
        </div>
      </div>
    </div>
  );
};
