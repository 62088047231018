import React from "react";

import "./Loader.css";

export const Loader = () => {
  return (
    <div className="loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export const ButtonLoader = () => {
  return (
    <div className="button-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
