import React, { useState } from "react";
import { storage } from "../../firebaseProvider";

import "./Article.css";
import { FormattedText } from "../FormattedText/FormattedText";
import { LinkButtonCenter, PDFButton } from "../Button/Button";
import PhotoCarousel from "../PhotoCarousel/PhotoCarousel";

const ArticleView = ({
  collection,
  photoNames,
  title,
  subtitle,
  content,
  linkUrl,
  buttonText,
  buttonType,
  expandable,
}) => {
  const [photoUrls, setPhotoUrls] = useState([]);
  const [initLoadUrl, setInitLoadUrl] = useState(false);

  if (photoNames && !initLoadUrl) {
    setInitLoadUrl(true);
    for (const item of photoNames) {
      storage
        .child(collection)
        .child(item)
        .getDownloadURL()
        .then((url) => setPhotoUrls((oldState) => [...oldState, url]));
    }
  }

  return (
    <div className="article">
      {photoUrls.length === 1 && <img src={photoUrls[0]} alt=""></img>}
      {photoUrls.length > 1 && <PhotoCarousel photoUrls={photoUrls} />}
      {title !== "" && <h1>{title}</h1>}
      {subtitle !== "" && <h2>{subtitle}</h2>}
      {(title !== "" || subtitle !== "" || photoNames !== "") &&
        content !== "" && <hr />}
      {content !== "" && (
        <FormattedText data={content} expandable={expandable} />
      )}
      {buttonType === "pdf" && (
        <div className="article-link-button">
          <PDFButton link={linkUrl} text={buttonText} />
        </div>
      )}
      {buttonType === "link" && (
        <div className="article-link-button">
          <LinkButtonCenter link={linkUrl} text={buttonText} />
        </div>
      )}
    </div>
  );
};

export default ArticleView;
