import React, { useState } from "react";
import { storage, storeData, deleteData } from "../../firebaseProvider";
import i18next from "i18next";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "./Article.css";

import ArticleView from "./ArticleView";
import ArticleEdit from "./ArticleEdit";
import { SecundaryButton, IconButton } from "../Button/Button";

const ArticleGrid = ({
  user,
  data,
  collection,
  editable,
  numPerRow,
  reloadData,
  expandable,
}) => {
  const getNewsContainers = () => {
    return data.map((newsItemData) => {
      return (
        <div className="article-grid-item" key={newsItemData.id}>
          <ArticleItem
            user={user}
            data={newsItemData}
            collection={collection}
            editable={editable}
            reloadData={reloadData}
            expandable={expandable}
          />
        </div>
      );
    });
  };

  const articleGridClass = "article-grid-" + numPerRow.toString();

  return (
    <div id="article-grid" className={articleGridClass}>
      {data && getNewsContainers()}
    </div>
  );
};

const ArticleItem = ({
  user,
  collection,
  data,
  editable,
  reloadData,
  expandable,
}) => {
  const [newData, setNewData] = useState(data);
  const [saveSuccess, setSaveSuccess] = useState();
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(editMode ? false : true);
  };

  const deleteArticle = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmRes = confirm("Willst du diesen Artikel wirklich löschen?");
    if (confirmRes) {
      deleteData(collection, data.id)
        .then(() => {
          reloadData();
        })
        .catch((err) => {
          console.log("error deleting", err);
        });
    }
  };

  const saveArticle = (updatedData, oldPhotoNames, newPhotos) => {
    setNewData({ id: data.id, data: updatedData });

    storeData(collection, data.id, updatedData)
      .then(() => {
        if (updatedData.photoNames !== oldPhotoNames) {
          if (oldPhotoNames) {
            let photosToDelete = oldPhotoNames.filter(
              (x) => !updatedData.photoNames.includes(x)
            );
            for (const item of photosToDelete) {
              storage.child(collection).child(item).delete();
            }
          }
          if (newPhotos) {
            for (const item of newPhotos) {
              storage.child(collection).child(item.name).put(item);
            }
          }
        }
        setEditMode(false);
      })
      .catch((err) => {
        setSaveSuccess(false);
      });
  };

  const getNewsItem = () => {
    const articleData =
      i18next.language.substring(0, 2) === "de"
        ? [
            newData.data.photoNames,
            newData.data.titleDE,
            newData.data.subtitleDE,
            newData.data.contentDE,
            newData.data.linkUrlDE,
            newData.data.buttonTextDE,
            newData.data.buttonType,
          ]
        : [
            newData.data.photoNames,
            newData.data.titleEN,
            newData.data.subtitleEN,
            newData.data.contentEN,
            newData.data.linkUrlEN,
            newData.data.buttonTextEN,
            newData.data.buttonType,
          ];

    if (user && editMode) {
      return (
        <div>
          <ArticleEdit
            data={newData.data}
            setData={saveArticle}
            saveSuccess={saveSuccess}
          />
          <div className="article-edit-button">
            <SecundaryButton
              onClick={toggleEditMode}
              text={"bearbeiten beenden"}
              disabled={false}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <ArticleView
            collection={collection}
            id={data.id}
            photoNames={articleData[0]}
            title={articleData[1]}
            subtitle={articleData[2]}
            content={articleData[3]}
            linkUrl={articleData[4]}
            buttonText={articleData[5]}
            buttonType={articleData[6]}
            expandable={expandable}
          />
          {user && editable && (
            <div>
              <div className="article-edit-button">
                <IconButton onClick={toggleEditMode} icon={<FaEdit />} />
                <IconButton onClick={deleteArticle} icon={<FaTrashAlt />} />
              </div>
              {collection === "news" && (
                <div className="article-url">
                  <a href={"https://www.kidzplanet.ch/news/" + data.id}>
                    {"https://www.kidzplanet.ch/news/" + data.id}
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  };

  return getNewsItem();
};

export default ArticleGrid;
