import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getSortedData } from "../../firebaseProvider";

import { Title } from "../../components/Title/Title";
import ArticleGrid from "../../components/Article/ArticleGrid";

import "./Home.css";

const Ambassadors = ({ user }) => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const loadData = () => {
    getSortedData("ambassadors").then((input) => setData(input));
  };

  if (!data) {
    loadData();
  }

  return (
    <div id="ambassadors">
      <Title text={t("home.ambassadors")} />
      <ArticleGrid
        user={user}
        data={data}
        collection="ambassadors"
        editable={true}
        numPerRow={6}
        reloadData={loadData}
        expandable={false}
      />
    </div>
  );
};

export default Ambassadors;
