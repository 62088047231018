import React, { useState } from "react";
import { firestore } from "../../firebaseProvider";

import "./VideoPlayer.css";
import { SecundaryButton } from "../Button/Button";
import SaveInfoText from "../SaveInfoText/SaveInfoText";

const VideoPlayerCreate = ({ onSave, order }) => {
  const [newTitleDE, setNewTitleDE] = useState("");
  const [newTitleEN, setNewTitleEN] = useState("");
  const [newURLDE, setNewURLDE] = useState("");
  const [newURLEN, setNewURLEN] = useState("");

  const [showInfo, setShowInfo] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState();
  const [noDataEntered, setNoDataEntered] = useState();

  const resetValues = () => {
    setNewTitleDE("");
    setNewTitleEN("");
    setNewURLDE("");
    setNewURLEN("");
  };

  const saveChanges = () => {
    if (newTitleDE && newTitleEN && newURLDE && newURLEN) {
      const dataToStore = {
        titleDE: newTitleDE,
        titleEN: newTitleEN,
        urlDE: newURLDE,
        urlEN: newURLEN,
        order: order,
      };

      firestore
        .collection("videos")
        .doc()
        .set(dataToStore)
        .then(() => {
          setSaveSuccess(true);
          setShowInfo(true);
          resetValues();
          onSave();
        })
        .catch((err) => {
          setSaveSuccess(false);
          setShowInfo(true);
        });
    } else {
      setShowInfo(true);
      setNoDataEntered(true);
    }
  };

  const resetInfoValues = () => {
    setShowInfo(false);
    setNoDataEntered();
  };

  return (
    <div className="video">
      <div className="video-create">
        <h2>Deutsche Version</h2>
        <div>
          <label>Titel</label>
          <input
            type="text"
            value={newTitleDE}
            onInput={(e) => setNewTitleDE(e.target.value)}
            onChange={() => showInfo && resetInfoValues()}
          />
        </div>
        <div>
          <label>URL</label>
          <input
            type="text"
            value={newURLDE}
            onInput={(e) => setNewURLDE(e.target.value)}
            onChange={() => showInfo && resetInfoValues()}
          />
        </div>
      </div>
      <hr />
      <div className="video-create">
        <h2>Englische Version</h2>
        <div>
          <label>Titel</label>
          <input
            type="text"
            value={newTitleEN}
            onInput={(e) => setNewTitleEN(e.target.value)}
            onChange={() => showInfo && resetInfoValues()}
          />
        </div>
        <div>
          <label>URL</label>
          <input
            type="text"
            value={newURLEN}
            onInput={(e) => setNewURLEN(e.target.value)}
            onChange={() => showInfo && resetInfoValues()}
          />
        </div>
        <div className="save-button">
          <SecundaryButton
            onClick={saveChanges}
            text={"Speichern"}
            disabled={false}
          />
        </div>
        {showInfo && noDataEntered && (
          <div className="form-info">
            <p>Titel und URL dürfen nicht leer sein.</p>
          </div>
        )}
        {showInfo && <SaveInfoText saveSuccess={saveSuccess} />}
      </div>
    </div>
  );
};

export default VideoPlayerCreate;
