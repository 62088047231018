import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth } from "../../firebaseProvider";

import NavContainer from "../Navigation/NavMenu/NavContainer";
import NavLink from "../Navigation/NavMenu/NavLink";
import LngChangerDropdown from "../LngChanger/LngChangerDrowdown";
import { SecundaryButton } from "../Button/Button";
import { VerticalLine } from "../Navigation/VerticalLine";

export const NavP = styled.p`
  margin: 0px;
  display: inline-block;
  font-size: 16px;
`;

const checkHomeActive = () => {
  const pathname = window.location.pathname;
  return pathname === "/";
};

const NavMenu = ({ className, user, signOut }) => {
  const { t } = useTranslation();

  return (
    <NavContainer className={className}>
      <NavLink to="/" isActive={checkHomeActive}>
        <NavP>{t("menubar.home")}</NavP>
      </NavLink>
      <NavLink to="/projects">
        <NavP>{t("menubar.projects")}</NavP>
      </NavLink>
      <NavLink to="/immobiliengolf">
        <NavP>{t("menubar.immogolf")}</NavP>
      </NavLink>
      <NavLink to="/immobilienball">
        <NavP>{t("menubar.immoball")}</NavP>
      </NavLink>
      <NavLink to="/donation">
        <NavP>{t("menubar.donation")}</NavP>
      </NavLink>
      <NavLink to="/about">
        <NavP>{t("menubar.ueberuns")}</NavP>
      </NavLink>
      <NavLink to="/contact">
        <NavP>{t("menubar.contact")}</NavP>
      </NavLink>
      <VerticalLine />
      {user && (
        <div key="logout">
          <div id="logout-button">
            <SecundaryButton onClick={signOut} text="Logout" disabled={false} />
          </div>
        </div>
      )}
      {user && (
        <NavLink to="/news">
          {" "}
          <NavP>{t("menubar.news")}</NavP>
        </NavLink>
      )}
      {!user && <LngChangerDropdown />}
    </NavContainer>
  );
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(NavMenu);
