import React, { useState } from "react";
import { getSortedData } from "../../firebaseProvider";

import "./News.css";

import ArticleGrid from "../../components/Article/ArticleGrid";
import { Title } from "../../components/Title/Title";

const NewsOverview = ({ user }) => {
  const [data, setData] = useState();
  const loadData = () => {
    getSortedData("news").then((input) => setData(input));
  };

  if (!data) {
    loadData();
  }

  return (
    <div id="news-overview">
      <Title text="News" pageLink="./news" />
      <ArticleGrid
        user={user}
        data={data && data.slice(0, 4)}
        collection="news"
        editable={false}
        numPerRow={4}
        reloadData={loadData}
        expandable={true}
      />
    </div>
  );
};

export default NewsOverview;
