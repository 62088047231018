import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { storage } from "../../firebaseProvider";

import { Title } from "../Title/Title";

import "./Donators.css";

const EventDonatorsView = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div id="donators-view">
      <Title text={t("home.donators")} />
      {data && (
        <div>
          <div className="event-donators-grid">
            <div>
              <div className="grid-item event-partners">
                <h2>{t("events.countryPartner")}</h2>
                {data["country-partner"] && data["country-partner"].map((item) => (
                  <DonatorImage
                    key={item}
                    imageName={item}
                  />
                ))}
              </div>
            </div>
            <div >
              <h2>{t("events.supporter")}</h2>
              {data["supporter"] &&
                data["supporter"].map((item) => (
                  <div className="event-supporter">
                    <DonatorImage key={item} imageName={item} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const DonatorImage = ({ imageName }) => {
  const [photoUrl, setPhotoUrl] = useState();
  if (imageName && !photoUrl) {
    storage
      .child("donators")
      .child(imageName)
      .getDownloadURL()
      .then((url) => setPhotoUrl(url));
  }

  return (
    <div className="donator-img">
      {photoUrl && <img src={photoUrl} alt=""></img>}
    </div>
  );
};

export default EventDonatorsView;
