import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { storage, storeData } from "../../firebaseProvider";

import { Title } from "../Title/Title";
import { FaTrashAlt, FaSave } from "react-icons/fa";
import { IconButton } from "../Button/Button";

import "./Donators.css";

const DonatorsEdit = ({ initData, reloadData }) => {
  const { t } = useTranslation();
  const countryPartner = initData["country-partner"];

  const [fundraisingPartner, setFundraisingPartner] = useState(
    initData["fundraising-partner"]
  );
  const [newFundraisingLogo, setNewFundraisingLogo] = useState();
  const [saveFundraisingLoading, setSaveFundraisingLoading] = useState(false);

  const deleteDonatorLogo = (dataToDelete) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmRes = confirm("Willst du diesen Sponsor wirklich löschen?");
    if (confirmRes) {
      let newData = [...fundraisingPartner];
      newData = newData.filter((item) => item !== dataToDelete);
      storeData("donators", "kidzplanet", {
        "country-partner": countryPartner,
        "fundraising-partner": newData,
      }).then(() => {
        storage
          .child("donators")
          .child(dataToDelete)
          .delete()
          .then(() => {
            setFundraisingPartner(newData);
          });
      });
    }
  };

  const storeNewLogo = () => {
    setSaveFundraisingLoading(true);
    if (newFundraisingLogo) {
      let newData = [...fundraisingPartner];
      newData = newData.concat(newFundraisingLogo.name);
      storeData("donators", "kidzplanet", {
        "country-partner": countryPartner,
        "fundraising-partner": newData,
      })
        .then(() => {
          console.log("store logo", newFundraisingLogo.name);
          storage
            .child("donators")
            .child(newFundraisingLogo.name)
            .put(newFundraisingLogo)
            .then(() => {
              setFundraisingPartner(newData);
              setSaveFundraisingLoading(false);
            })
            .catch((err) => {
              console.log("error store", err);
            });
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const fundraisingOnChangeHandler = (event) => {
    setNewFundraisingLogo(event.target.files[0]);
  };

  return (
    <div id="donators-edit">
      <Title text={t("home.donators")} />
      <div className="donators-grid">
        <div className="grid-item main-partners">
          <h2>{t("home.countryPartners")}</h2>
          {countryPartner &&
            countryPartner.map((item) => (
              <DonatorImage key={item} imageName={item} enableDelete={false} />
            ))}
        </div>
        <div className="grid-item fundraising-partners">
          <h2>{t("home.fundraisingPartners")}</h2>
          {fundraisingPartner &&
            fundraisingPartner.map((item) => (
              <DonatorImage
                key={item}
                imageName={item}
                deleteData={deleteDonatorLogo}
                enableDelete={true}
              />
            ))}
          <p>neuer Sponsor hinzufügen</p>
          <div className="donators-file-upload">
            <input
              type="file"
              name="file"
              onChange={fundraisingOnChangeHandler}
            />
            <div className="upload-name">
              <p>{newFundraisingLogo && newFundraisingLogo.name}</p>
            </div>
          </div>
          <div className="save-button">
            <IconButton
              icon={<FaSave />}
              onClick={storeNewLogo}
              loading={saveFundraisingLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DonatorImage = ({ imageName, deleteData, enableDelete }) => {
  const [photoUrl, setPhotoUrl] = useState();

  const onClick = () => {
    deleteData(imageName);
  };

  if (imageName && !photoUrl) {
    storage
      .child("donators")
      .child(imageName)
      .getDownloadURL()
      .then((url) => setPhotoUrl(url));
  }
  return (
    <div className="donator-logo-item">
      <div className="donator-img">
        {photoUrl && <img src={photoUrl} alt=""></img>}
      </div>
      {enableDelete && (
        <div className="delete-button">
          <IconButton onClick={onClick} icon={<FaTrashAlt />} />
        </div>
      )}
    </div>
  );
};

export default DonatorsEdit;
