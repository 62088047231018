import React from "react";
import { useTranslation } from "react-i18next";

import "./Contact.css";
import Form from "../../components/Form/Form";
import FormInput from "../../components/Form/FormInput";
import { FooterSmall } from "../../components/Footer/Footer";
import { TitleSmall } from "../../components/Title/Title";
import styled from "styled-components";

const Contact = () => {
  const { t } = useTranslation();

  const SendButtonText = styled.div`
    color: white;
    font-size: 18px;
  `;

  const ErrorMessage = styled.div`
    color: red;
  `;

  const SuccessMessage = styled.div`
    color: green;
  `;

  return (
    <div id="contact">
      <div className="contact-form">
        <div className="contact-content-form">
          <h1>{t("contact.title")}</h1>
          <hr />
          <Form
            title={TitleSmall}
            sendButtonText={
              <SendButtonText>{t("contact.send")}</SendButtonText>
            }
            successMessage={
              <SuccessMessage>{t("contact.successMessage")}</SuccessMessage>
            }
            errorMessage={
              <ErrorMessage>{t("contact.errorMessage")}</ErrorMessage>
            }
          >
            <FormInput
              placeholder={t("contact.firstName")}
              type="text"
              name="firstName"
              marginTop="60px"
              marginBottom="20px"
              width="60%"
            />
            <FormInput
              placeholder={t("contact.lastName")}
              type="text"
              name="lastName"
              marginBottom="20px"
              width="60%"
            />
            <FormInput
              placeholder={t("contact.email")}
              type="email"
              name="email"
              marginBottom="20px"
              width="60%"
            />
            <FormInput
              placeholder={t("contact.message")}
              name="message"
              type="textarea"
              marginBottom="15px"
              width="60%"
              multiline
            />
          </Form>
        </div>
      </div>
      <div className="contact-content-address">
        <div className="address-content">
          <TitleSmall text={t("contact.address")} />
          <p>
            kidz planet <br /> Kastellstrasse 23 <br /> 8107 Buchs <br /> +41 79
            610 47 70
          </p>
        </div>
        <div className="address-content">
          <TitleSmall text={t("contact.account")} />
          <p>
            Raiffeisenbank <br />
            Züri-Unterland <br />
            8180 Bülach <br />
            IBAN: CH75 8080 8006 5101 2921 3 <br />
            SWIFT-BIC: RAIFCH22E75 <br />
            BC-Nr.:81475
          </p>
        </div>
      </div>
      <FooterSmall />
    </div>
  );
};

export default Contact;
