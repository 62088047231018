import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./language/English.json";
import German from "./language/German.json";

const resources = {
  en: {
    translation: English,
  },
  de: {
    translation: German,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "de",
  fallbackLng: "de",
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: "languageChanged",
  },
});

export default i18n;
