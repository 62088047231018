import React, { useState } from "react";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth, getSortedData } from "../../firebaseProvider";
import "./Donation.css";
import { useTranslation } from "react-i18next";

import ArticleGrid from "../../components/Article/ArticleGrid";
import { Title } from "../../components/Title/Title";

const Donation = ({ user }) => {
  const [data, setData] = useState();
  const { t } = useTranslation();
  const loadData = () => {
    getSortedData("donation").then((input) => setData(input));
  };

  if (!data) {
    loadData();
  }

  const getDonationView = () => {
    if (data) {
      return (
        <div id="donation">
          <div>
            <Title text={t("general.donateNow")} />
            <div className="height-10" />
            <ArticleGrid
              user={user}
              data={data}
              collection="donation"
              editable={false}
              numPerRow={4}
              reloadData={loadData}
              expandable={false}
            />
          </div>
        </div>
      );
    } else {
      return <div>no data</div>;
    }
  };

  return getDonationView();
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(Donation);
