import React from "react";
import ReactPlayer from "react-player";

import "./VideoPlayer.css";

const VideoPlayerView = ({ title, url }) => {
  return (
    <div className="video-view">
      <ReactPlayer
        url={url}
        width={(window.innerWidth>1400 ? 700 : window.innerWidth*0.9).toString()+"px"}
        height={(window.innerWidth>1400 ? 400 : window.innerWidth/1.97).toString()+"px"}
        playing={false}
        volume={0.2}
        muted={false}
        controls={true}
      />
      <h3>{title}</h3>
    </div>
  );
};

export default VideoPlayerView;
