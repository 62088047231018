import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlainButton } from "../Button/Button";
import ReactHtmlParser from "react-html-parser";

import "./FormattedText.css";

export const FormattedText = ({ data, expandable }) => {
  const [expand, setExpand] = useState(false);
  const { t } = useTranslation();

  const getData = () => {
    const initData =
      !expand && expandable && data.length > 300
        ? data.substring(0, 300)
        : data;

    const splittedData = initData.split("\n");
    let counter = 0;

    const dataElements = splittedData.map((split, index) => {
      counter += 1;
      if (index === splittedData.length - 1) {
        return (
          <div key={counter.toString()}>
            <p>{ReactHtmlParser(split)}</p>
          </div>
        );
      } else {
        return split === "" ? (
          <div key={counter.toString()}>
            <br />
          </div>
        ) : (
          <div key={counter.toString()}>
            <p>{ReactHtmlParser(split)}</p>
            <br />
          </div>
        );
      }
    });

    return dataElements;
  };

  return (
    <div className="formatted-text">
      {getData()}
      {data.length > 300 && expandable && (
        <PlainButton
          text={expand ? t("general.less") : t("general.more")}
          onClick={() => setExpand(!expand)}
        />
      )}
    </div>
  );
};
