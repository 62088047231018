import React, { useState } from "react";

import "./InfoRow.css";
import { SecundaryButton } from "../Button/Button";
import SaveInfoText from "../SaveInfoText/SaveInfoText";

const InfoRowEdit = ({ data, setData, saveSuccess }) => {
  const [newTitleDE, setNewTitleDE] = useState(data.titleDE);
  const [newContentDE, setNewContentDE] = useState(data.contentDE);
  const [newButtonLinkDE, setNewButtonLinkDE] = useState();

  const [newTitleEN, setNewTitleEN] = useState(data.titleEN);
  const [newContentEN, setNewContentEN] = useState(data.contentEN);
  const [newButtonLinkEN, setNewButtonLinkEN] = useState();

  const [noDataEntered, setNoDataEntered] = useState();
  const [showInfo, setShowInfo] = useState(false);

  const saveChanges = () => {
    if (newTitleDE && newContentDE && newTitleEN && newContentEN) {
      const dataToStore = !data.buttonLinkDE
        ? {
            titleDE: newTitleDE,
            contentDE: newContentDE,
            titleEN: newTitleEN,
            contentEN: newContentEN,
            order: data.order,
            contentType: data.contentType,
          }
        : {
            titleDE: newTitleDE,
            contentDE: newContentDE,
            buttonLinkDE: newButtonLinkDE
              ? newButtonLinkDE.name
              : data.buttonLinkDE,
            buttonTextDE: data.buttonTextDE,
            titleEN: newTitleEN,
            contentEN: newContentEN,
            buttonLinkEN: newButtonLinkEN
              ? newButtonLinkEN.name
              : data.buttonLinkEN,
            buttonTextEN: data.buttonTextEN,
            order: data.order,
            contentType: data.contentType,
          };
      setData(
        dataToStore,
        [data.buttonLinkDE, data.buttonLinkEN],
        [newButtonLinkDE, newButtonLinkEN]
      );
      setShowInfo(true);
    } else {
      setShowInfo(true);
      setNoDataEntered(true);
    }
  };

  const resetValues = () => {
    setShowInfo(false);
    setNoDataEntered();
  };

  return (
    <div className="infoRow">
      <div className="infoRow-edit">
        <h2>Deutsche Version</h2>
        {data.contentType === "text" ? (
          <div>
            <div>
              <label>Titel</label>
              <input
                value={newTitleDE}
                onInput={(e) => setNewTitleDE(e.target.value)}
                onChange={() => showInfo && resetValues()}
              />
            </div>
            <div>
              <label>Content</label>
              <textarea
                value={newContentDE}
                onInput={(e) => setNewContentDE(e.target.value)}
                onChange={() => showInfo && resetValues()}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {data.buttonLinkDE && (
          <div>
            <label>neues {data.buttonTextDE} hochladen</label>
            <input
              type="file"
              name="file"
              onChange={(event) => setNewButtonLinkDE(event.target.files[0])}
            />
          </div>
        )}
      </div>
      <hr />
      <div className="infoRow-edit">
        <h2>Englische Version</h2>
        {data.contentType === "text" ? (
          <div>
            <div>
              <label>Titel</label>
              <input
                value={newTitleEN}
                onInput={(e) => setNewTitleEN(e.target.value)}
                onChange={() => showInfo && resetValues()}
              />
            </div>

            <div>
              <label>Content</label>

              <textarea
                value={newContentEN}
                onInput={(e) => setNewContentEN(e.target.value)}
                onChange={() => showInfo && resetValues()}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {data.buttonLinkEN && (
          <div>
            <label>neues {data.buttonTextDE} hochladen</label>
            <input
              type="file"
              name="file"
              onChange={(event) => setNewButtonLinkEN(event.target.files[0])}
            />
          </div>
        )}
        <div className="save-button">
          <SecundaryButton
            onClick={saveChanges}
            text={"Speichern"}
            disabled={false}
          />
        </div>
        {showInfo && noDataEntered && (
          <div className="form-info">
            <p>Titel und Text dürfen nicht leer sein.</p>
          </div>
        )}
        {showInfo && <SaveInfoText saveSuccess={saveSuccess} />}
      </div>
    </div>
  );
};

export default InfoRowEdit;
