import React from "react";
import { useTranslation } from "react-i18next";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth } from "../../firebaseProvider";
import "./Projects.css";

import { Footer } from "../../components/Footer/Footer";
import { Title } from "../../components/Title/Title";
import InfoRow from "../../components/InfoRow/InfoRow";

const Engagements = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div id="engagements">
      <Title text={t("projects.engagements")} />
      <div className="engagements-content-intro">
        <InfoRow user={user} collection="engagements-info" numOfItems={1} />
      </div>
      <Footer />
    </div>
  );
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(Engagements);
