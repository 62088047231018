import React, { useState } from "react";
import { getDocument } from "../../firebaseProvider";
import DonatorsView from "./DonatorsView";
import DonatorsEdit from "./DonatorsEdit";
import { SecundaryButton } from "../Button/Button";

import "./Donators.css";

const Donators = ({ user }) => {
  const [data, setData] = useState();
  const [editMode, setEditMode] = useState(false);

  const loadData = () => {
    getDocument("donators", "kidzplanet").then((input) => setData(input));
  };

  if (!data) {
    loadData();
  }

  const toggleEditMode = () => {
    setEditMode(editMode ? false : true);
  };

  return (
    <div id="donators">
      {user && editMode ? (
        <div>
          <DonatorsEdit initData={data} reloadData={loadData()} />
          <div className="donators-edit-button">
            <SecundaryButton
              onClick={toggleEditMode}
              text={"bearbeiten beenden"}
              disabled={false}
            />
          </div>
        </div>
      ) : (
        <div>
          <DonatorsView data={data} />
          {user && (
            <div className="donators-edit-button">
              <SecundaryButton
                onClick={toggleEditMode}
                text={"Spender bearbeiten"}
                disabled={false}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Donators;
