import React, { useState } from "react";

import { getSortedData, storeData, storage } from "../../firebaseProvider";
import "./InfoRow.css";

import InfoRowView from "./InfoRowView";
import InfoRowEdit from "./InfoRowEdit";
import i18next from "i18next";
import { SecundaryButton, IconButton } from "../Button/Button";
import { FaEdit } from "react-icons/fa";

const InfoRow = ({ user, collection, numOfItems }) => {
  const [data, setData] = useState();

  if (!data) {
    getSortedData(collection).then((input) => setData(input));
  }

  const getInfoRowContainers = () => {
    return data.map((newsItemData) => {
      return (
        <div className="infoRow-grid-item" key={newsItemData.id}>
          <InfoRowItem
            user={user}
            data={newsItemData}
            collection={collection}
          />
        </div>
      );
    });
  };

  return (
    <div className={"infoRow-grid infoRow-grid-" + numOfItems.toString()}>
      {data && getInfoRowContainers()}
    </div>
  );
};

const InfoRowItem = ({ user, collection, data }) => {
  const [newData, setNewData] = useState(data);
  const [saveSuccess, setSaveSuccess] = useState();
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(editMode ? false : true);
  };

  const saveContent = (updatedData, oldFiles, newFiles) => {
    for (var i = 0; i < newFiles.length; i++) {
      if (newFiles[i]) {
        storage.child(collection).child(oldFiles[i]).delete();
        storage.child(collection).child(newFiles[i].name).put(newFiles[i]);
      }
    }
    storeData(collection, data.id, updatedData)
      .then(() => {
        setEditMode(false);
        setNewData({ id: data.id, data: updatedData });
      })
      .catch(() => setSaveSuccess(false));
  };

  const getNewsItem = () => {
    const articleData =
      i18next.language.substring(0, 2) === "de"
        ? [
            newData.data.titleDE,
            newData.data.contentDE,
            newData.data.buttonTextDE,
            newData.data.buttonLinkDE,
          ]
        : [
            newData.data.titleEN,
            newData.data.contentEN,
            newData.data.buttonTextEN,
            newData.data.buttonLinkEN,
          ];

    if (user && editMode) {
      return (
        <div>
          <InfoRowEdit
            data={newData.data}
            setData={saveContent}
            saveSuccess={saveSuccess}
          />
          <div className="infoRow-edit-button">
            <SecundaryButton
              onClick={toggleEditMode}
              text={"bearbeiten beenden"}
              disabled={false}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <InfoRowView
            title={articleData[0]}
            content={articleData[1]}
            contentType={newData.data.contentType}
            buttonText={articleData[2]}
            onClickLink={articleData[3]}
            collection={collection}
          />
          {user && (
            <div className="infoRow-edit-button">
              <IconButton onClick={toggleEditMode} icon={<FaEdit />} />
            </div>
          )}
        </div>
      );
    }
  };

  return getNewsItem();
};

export default InfoRow;
