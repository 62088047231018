import React, { useState } from "react";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth, getSortedData } from "../../firebaseProvider";
import "./Videos.css";

import VideoPlayerGrid from "../../components/VideoPlayer/VideoPlayerGrid.js";
import VideoPlayerCreate from "../../components/VideoPlayer/VideoPlayerCreate";
import { Title } from "../../components/Title/Title";

const Videos = ({ user }) => {
  const [data, setData] = useState();

  const loadData = () => {
    getSortedData("videos").then((input) => setData(input));
  };

  if (!data) {
    loadData();
  }

  const getVideosView = () => {
    if (data) {
      return (
        <div id="videos">
          {user && (
            <div>
              <Title text="Video hinzufügen" />
              <div className="videos-create">
                <VideoPlayerCreate onSave={loadData} order={data.length + 1} />
              </div>
            </div>
          )}
          <div>
            <Title text={user ? "Videos bearbeiten" : "Videos"} />
            <VideoPlayerGrid user={user} data={data} reloadData={loadData} />
          </div>
        </div>
      );
    } else {
      return <div>no data</div>;
    }
  };

  return getVideosView();
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(Videos);
