import React from "react";
import { useTranslation } from "react-i18next";

import "./Title.css";
import { LinkButton } from "../Button/Button";

export const Title = ({ text, pageLink }) => {
  const { t } = useTranslation();

  return (
    <div className="title">
      <div></div>
      <div>
        <h1>{text}</h1>
        <hr />
      </div>
      {pageLink && (
        <div className="title-button-container">
          <LinkButton text={t("general.more")} link={pageLink} />
        </div>
      )}
    </div>
  );
};

export const TitleSmall = ({ text, pageLink }) => {
  const { t } = useTranslation();

  return (
    <div className="title-small">
      <div></div>
      <div>
        <h2>{text}</h2>
        <hr />
      </div>
      {pageLink && (
        <div className="title-button-container">
          <LinkButton text={t("general.more")} link={pageLink} />
        </div>
      )}
    </div>
  );
};
