const firebaseConfig = {
  apiKey: "AIzaSyARlEgyAX1FNTdlOpbw6VHINf24gSi8vf8",
  authDomain: "kidzplanet-website.firebaseapp.com",
  databaseURL: "https://kidzplanet-website.firebaseio.com",
  projectId: "kidzplanet-website",
  storageBucket: "kidzplanet-website.appspot.com",
  messagingSenderId: "706021626201",
  appId: "1:706021626201:web:99015c5a0f5cb76d7b3c48",
  measurementId: "G-LD779X6358",
};

export default firebaseConfig;
