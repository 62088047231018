import React, { useState } from "react";
import { getDocument } from "../../firebaseProvider";
import EventDonatorsView from "./EventDonatorsView";

import "./Donators.css";

const EventDonators = ({ category }) => {
  const [data, setData] = useState();

  const loadData = () => {
    getDocument("donators", category).then((input) => setData(input));
  };

  if (!data) {
    loadData();
  }

  return (
    <div id="donators">
      <EventDonatorsView data={data} />
    </div>
  );
};

export default EventDonators;
