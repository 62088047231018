import React, { useState } from "react";
import { useParams } from "react-router";
import i18next from "i18next";

import { getDocument } from "../../firebaseProvider";
import "./News.css";

import ArticleView from "../../components/Article/ArticleView";
import { Title } from "../../components/Title/Title";

const SingleNews = () => {
  const [data, setData] = useState();
  const { id } = useParams();

  const loadData = () => {
    getDocument("news", id).then((input) => setData(input));
  };

  if (!data) {
    loadData();
  }

  const getNewsView = () => {
    const articleData =
      i18next.language.substring(0, 2) === "de"
        ? [
            data.photoNames,
            data.titleDE,
            data.subtitleDE,
            data.contentDE,
            data.linkUrlDE,
            data.buttonTextDE,
            data.buttonType,
          ]
        : [
            data.photoNames,
            data.titleEN,
            data.subtitleEN,
            data.contentEN,
            data.linkUrlEN,
            data.buttonTextEN,
            data.buttonType,
          ];

    return (
      <div id="single-news">
        <div>
          <Title text={"News"} />
          <div id="single-news-article">
            <ArticleView
              collection="news"
              photoNames={articleData[0]}
              title={articleData[1]}
              subtitle={articleData[2]}
              content={articleData[3]}
              linkUrl={articleData[4]}
              buttonText={articleData[5]}
              buttonType={articleData[6]}
              expandable={false}
            />
          </div>
        </div>
      </div>
    );
  };

  return data ? getNewsView() : <div>no data</div>;
};

export default SingleNews;
