import React, { useState } from "react";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth, getSortedData } from "../../firebaseProvider";
import "./Presse.css";

import ArticleGrid from "../../components/Article/ArticleGrid";
import ArticleCreate from "../../components/Article/ArticleCreate";
import { Title } from "../../components/Title/Title";

const Presse = ({ user }) => {
  const [data, setData] = useState();
  const loadData = () => {
    getSortedData("presse").then((input) => setData(input));
  };

  if (!data) {
    loadData();
  }

  const getPresseView = () => {
    if (data) {
      return (
        <div id="presse">
          {user && (
            <div>
              <Title text="Presse Artikel hinzufügen" />
              <div className="presse-create">
                <div className="presse-create-article">
                  <ArticleCreate
                    collection="presse"
                    onSave={loadData}
                    order={data.length + 1}
                  />
                </div>
                <div className="presse-create-facebook">facebook</div>
                <div className="presse-create-twitter">twitter</div>
              </div>
            </div>
          )}
          <div>
            <Title
              text={user ? "Presse Artikel bearbeiten" : "Presse Artikel"}
            />
            <ArticleGrid
              user={user}
              data={data}
              collection="presse"
              editable={true}
              numPerRow={3}
              reloadData={loadData}
              expandable={true}
            />
          </div>
        </div>
      );
    } else {
      return <div>no data</div>;
    }
  };

  return getPresseView();
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(Presse);
