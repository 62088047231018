import React, { useState } from "react";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth } from "../../firebaseProvider";
import { SecundaryButton } from "../../components/Button/Button";

import "./Login.css";

const Login = ({ signInWithEmailAndPassword, user }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [infoText, setInfoText] = useState("");
  const [loading, setLoading] = useState(false);

  if (user && loading) {
    setLoading(false);
  }

  const signIn = async () => {
    setLoading(true);
    if (email && password) {
      signInWithEmailAndPassword(email, password);
      setTimeout(() => {
        setLoading(false);
        if (!user) {
          setInfoText("Die E-Mail Adresse oder das Passwort ist falsch.");
        }
      }, 3000);
    } else {
      setLoading(false);
      setInfoText("Gib eine E-Mail Adresse und ein Passwort ein.");
    }
  };

  return (
    <div id="login">
      {user ? (
        <p>Du hast dich erfolgreich eingeloggt</p>
      ) : (
        <div id="form">
          <h2>Login</h2>
          <div>
            <label>Email</label>
            <input
              value={email}
              onInput={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onInput={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <div id="login-button">
            <SecundaryButton
              onClick={signIn}
              text={"Login"}
              loading={loading}
              disabled={false}
            />
          </div>

          <p>{infoText}</p>
        </div>
      )}
    </div>
  );
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(Login);
