import React, { useState } from "react";

import { getDocument } from "../../firebaseProvider";
import "./Videos.css";

import MainVideoPlayer from "../../components/VideoPlayer/MainVideoPlayer";

const ImmoballVideoPlayer = ({ user }) => {
  const [videoUrl, setVideoUrl] = useState();

  if (!videoUrl) {
    getDocument("videos", "ImmoballVideos").then((input) => {
      setVideoUrl(input);
    });
  }

  return videoUrl ? (
    <div key="immo-video-section">
      <MainVideoPlayer user={user} data={videoUrl} id={"ImmoballVideo"} />
    </div>
  ) : (
    <div></div>
  );
};

export default ImmoballVideoPlayer;
