import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { getDocument, storeData } from "../../firebaseProvider";
import "./Immoball.css";

import { IconButton, SecundaryButton } from "../../components/Button/Button";
import SaveInfoText from "../../components/SaveInfoText/SaveInfoText";
import { FaEdit } from "react-icons/fa";

const BallTitle = ({ user }) => {
  const { t } = useTranslation();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [newTitleDE, setNewTitleDE] = useState();
  const [newSubtitleDE, setNewSubtitleDE] = useState();
  const [newTitleEN, setNewTitleEN] = useState();
  const [newSubtitleEN, setNewSubtitleEN] = useState();

  const [noDataEntered, setNoDataEntered] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const loadData = () => {
    getDocument("immoball", "title").then((input) => {
      i18next.language.substring(0, 2) === "de"
        ? setData({
            title: input.titleDE,
            subtitle: input.subtitleDE,
          })
        : setData({
            title: input.titleEN,
            subtitle: input.subtitleEN,
          });
      setNewTitleDE(input.titleDE);
      setNewSubtitleDE(input.subtitleDE);
      setNewTitleEN(input.titleEN);
      setNewSubtitleEN(input.subtitleEN);
    });
  };

  if (!data) {
    loadData();
  }

  const saveChanges = () => {
    setLoading(true);
    if (newTitleDE && newSubtitleDE && newTitleEN && newSubtitleEN) {
      const dataToStore = {
        titleDE: newTitleDE,
        subtitleDE: newSubtitleDE,
        titleEN: newTitleEN,
        subtitleEN: newSubtitleEN,
      };

      storeData("ball", "title", dataToStore)
        .then(() => {
          i18next.language.substring(0, 2) === "de"
            ? setData({
                title: newTitleDE,
                subtitle: newSubtitleDE,
              })
            : setData({
                title: newTitleEN,
                subtitle: newSubtitleEN,
              });
          setEditMode(false);
          setLoading(false);
        })
        .catch(() => {
          setShowErrorMessage(true);
          setLoading(false);
        });
    } else {
      setNoDataEntered(true);
    }
  };

  const resetValues = () => {
    setNoDataEntered(false);
    setShowErrorMessage(false);
  };

  return (
    <div id="immoball-title-container">
      <div id="immoball-title">
        {data && (
          <div>
            {user && editMode ? (
              <div>
                <div className="immoball-title-edit">
                  <h2>Deutsche Version</h2>
                  <div>
                    <input
                      value={newTitleDE}
                      onInput={(e) => setNewTitleDE(e.target.value)}
                      onChange={() =>
                        noDataEntered || (showErrorMessage && resetValues())
                      }
                    />
                  </div>
                  <div>
                    <input
                      value={newSubtitleDE}
                      onInput={(e) => setNewSubtitleDE(e.target.value)}
                      onChange={() =>
                        noDataEntered || (showErrorMessage && resetValues())
                      }
                    />
                  </div>
                </div>
                <div className="immoball-title-edit">
                  <h2>Englische Version</h2>
                  <div>
                    <input
                      value={newTitleEN}
                      onInput={(e) => setNewTitleEN(e.target.value)}
                      onChange={() =>
                        noDataEntered || (showErrorMessage && resetValues())
                      }
                    />
                  </div>
                  <div>
                    <input
                      value={newSubtitleEN}
                      onInput={(e) => setNewSubtitleEN(e.target.value)}
                      onChange={() =>
                        noDataEntered || (showErrorMessage && resetValues())
                      }
                    />
                  </div>
                  <div className="save-button">
                    <SecundaryButton
                      onClick={saveChanges}
                      text={"Speichern"}
                      loading={loading}
                      disabled={false}
                    />
                  </div>
                  {noDataEntered && (
                    <div className="form-info">
                      <p>Titel und Untertitel dürfen nicht leer sein.</p>
                    </div>
                  )}
                  {showErrorMessage && <SaveInfoText saveSuccess={false} />}
                </div>
              </div>
            ) : (
              <div>
                <div className="immoball-title1"><h1>{data.title}</h1></div>
                <div className="immoball-title2"><h1>{data.subtitle}</h1></div>
                <div className="immoball-title3"><p>{t("ball.title")}</p></div>
                {user && !editMode && (
                  <IconButton
                    icon={<FaEdit />}
                    onClick={() => setEditMode(true)}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BallTitle;
