import React from "react";
import "./Button.css";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { ButtonLoader } from "../Loader/Loader";

export const PrimaryButton = ({ text, onClick }) => {
  return (
    <div className="primary-button">
      <button onClick={() => onClick()}>{text}</button>
    </div>
  );
};

export const SecundaryButton = ({ text, onClick, loading, disabled }) => {
  return (
    <div className="secundary-button">
      <button onClick={() => onClick()} disabled={disabled}>
        {loading ? <ButtonLoader /> : text}
      </button>
    </div>
  );
};

export const LinkButton = ({ link, text }) => {
  return (
    <div className="link-button">
      <Link to={link}>
        <p>{text}</p>
      </Link>
    </div>
  );
};

export const LinkButtonCenter = ({ link, text }) => {
  return (
    <div className="link-button-center">
      <Link to={link}>
        <p>{text}</p>
      </Link>
    </div>
  );
};

export const PDFButton = ({ link, text }) => {
  return (
    <div className="pdf-button">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <p>{text}</p>
      </a>
    </div>
  );
};

export const IconButton = ({ icon, onClick, loading }) => {
  return (
    <div className="icon-button">
      <button onClick={() => onClick()}>
        <IconContext.Provider value={{ size: "17px" }}>
          {loading ? <ButtonLoader /> : <div>{icon}</div>}
        </IconContext.Provider>
      </button>
    </div>
  );
};

export const DonationButton = () => {
  const { t } = useTranslation();
  return (
    <div className="donation-button">
      <Link to="./donation">
        <p>{t("general.donateNow")}</p>
      </Link>
    </div>
  );
};

export const PlainButton = ({ text, onClick }) => {
  return (
    <div className="plain-button">
      <button onClick={() => onClick()}>
        <p>{text}</p>
      </button>
    </div>
  );
};
