import React, { useState } from "react";
import { storeData, deleteData } from "../../firebaseProvider";
import i18next from "i18next";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "./VideoPlayer.css";

import VideoPlayerView from "./VideoPlayerView";
import VideoPlayerEdit from "./VideoPlayerEdit";
import { SecundaryButton, IconButton } from "../Button/Button";

const VideoPlayerGrid = ({ user, data, reloadData }) => {
  const getVideoContainers = () => {
    return data.map((videoItemData) => {
      return (
        <div className="video-grid-item" key={videoItemData.id}>
          <VideoPlayerItem
            user={user}
            data={videoItemData}
            reloadData={reloadData}
          />
        </div>
      );
    });
  };

  return <div id="video-grid">{data && getVideoContainers()}</div>;
};

const VideoPlayerItem = ({ user, data, reloadData }) => {
  const [newData, setNewData] = useState(data);
  const [saveSuccess, setSaveSuccess] = useState();
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(editMode ? false : true);
  };

  const deleteVideo = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmRes = confirm("Willst du dieses Video wirklich löschen?");
    if (confirmRes) {
      deleteData("videos", data.id).then(() => {
        reloadData();
      });
    }
  };

  const saveVideo = (updatedData) => {
    setNewData({ id: data.id, data: updatedData });

    storeData("videos", data.id, updatedData)
      .then(() => {
        setEditMode(false);
      })
      .catch(() => {
        setSaveSuccess(false);
      });
  };

  const getVideoItem = () => {
    const videoData =
      i18next.language.substring(0, 2) === "de"
        ? [newData.data.titleDE, newData.data.urlDE]
        : [newData.data.titleEN, newData.data.urlEN];

    if (user && editMode) {
      return (
        <div>
          <VideoPlayerEdit
            data={newData.data}
            setData={saveVideo}
            saveSuccess={saveSuccess}
          />
          <div className="video-edit-button">
            <SecundaryButton
              onClick={toggleEditMode}
              text={"bearbeiten beenden"}
              disabled={false}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <VideoPlayerView title={videoData[0]} url={videoData[1]} />
          {user && (
            <div className="video-edit-button">
              <IconButton onClick={toggleEditMode} icon={<FaEdit />} />
              <IconButton onClick={deleteVideo} icon={<FaTrashAlt />} />
            </div>
          )}
        </div>
      );
    }
  };

  return getVideoItem();
};

export default VideoPlayerGrid;
