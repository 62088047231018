import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./App.css";

//import Menubar from "./components/Menubar/Menubar";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Immogolf from "./pages/Immogolf/Immogolf";
import Immoball from "./pages/Immoball/Immoball";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import News from "./pages/News/News";
import Videos from "./pages/Videos/Videos";
import Presse from "./pages/Presse/Presse";
import Donation from "./pages/Donation/Donation";
import Login from "./pages/Login/Login";
import KPLC from "./pages/Projects/KPLC";
import DivEngagements from "./pages/Projects/DivEngagements";
import Legal from "./pages/Legal/Legal";
import SingleNews from "./pages/News/SingleNews";

const theme = {
  colors: {
    primary: "#f27249",
    font1: "#000000",
    white: "#ffffff",
    grey: "#787878",
    middlegrey: "#ededed",
    lightgrey: "#f9f9f9",
    inputBorderColor: "#000000",
    black: "#000000",
    red: "#f22e2e",
  },
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div id="app">
        <Router>
          <div>
            <Header />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/kplc">
                <KPLC />
              </Route>
              <Route path="/engagements">
                <DivEngagements />
              </Route>
              <Route path="/projects">
                <KPLC />
              </Route>
              <Route path="/immobiliengolf">
                <Immogolf />
              </Route>
              <Route path="/immobilienball">
                <Immoball />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/news/:id">
                <SingleNews />
              </Route>
              <Route path="/news">
                <News />
              </Route>
              <Route path="/presse">
                <Presse />
              </Route>
              <Route path="/videos">
                <Videos />
              </Route>
              <Route path="/donation">
                <Donation />
              </Route>
              <Route path="/legal">
                <Legal />
              </Route>
              <Route path="/*">
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
